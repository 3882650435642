import React, { useState, useEffect } from "react";
import InfoContainer from "./InfoContainer";
import MapContainer from "./MapContainer";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import SlidingComponent from "./SlidingComponent";
import { jStat } from "jstat";

const OutletMaps = ({
  currentLocation,
  setCurrentLocation,
  currentAddress,
  setCurrentAddress,
  filteredOutlets,
  setFilteredOutlets,
  directions,
  setDirections,
  wayPoints,
  setWayPoints,
  setIsExpanded,
  IsExpanded,
}) => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [googleMapsLink, setGoogleMapsLink] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [eta, setEta] = useState(null);
  const [radius, setRadius] = useState(5);
  const [selectedSortOption, setSelectedSortOption] = useState("");
  const [selectedBeat, setSelectedBeat] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    categorySize: false,
    nonBilled: false,
  });

  const [categorySizeFilters, setCategorySizeFilters] = useState({
    ALL: false,
    "500001-ABOVE": true,
    "400001-500000": false,
    "300001-400000": false,
    "200001-300000": false,
    "100001-200000": false,
    "75001-100000": false,
    "50001-75000": false,
    "30001-50000": false,
    "20001-30000": false,
    "15001-20000": false,
    "10001-15000": false,
    "8001-10000": false,
    "5001-8000": false,
    "2001-5000": false,
    "0-2000": false,
  });

  const [nonBilledFilters, setNonBilledFilters] = useState({
    ALL: { filterValue: "ALL", isSelected: false },
    "7 days": { filterValue: 7, isSelected: true },
    "14 days": { filterValue: 14, isSelected: false },
    "30 days": { filterValue: 30, isSelected: false },
    "60 days": { filterValue: 60, isSelected: false },
  });

  const outletsPerPage = 4;

  const paginatedOutlets = Array.isArray(filteredOutlets)
    ? filteredOutlets
        // .filter((outlet) => {
        //   if (selectedFilters.categorySize) {
        //     const selectedCategories = Object.keys(categorySizeFilters)?.filter(
        //       (range) => categorySizeFilters[range]
        //     );

        //     if (!selectedCategories.includes(outlet.Category)) {
        //       return false;
        //     }
        //   }

        //   if (selectedFilters.nonBilled) {
        //     const selectedNonBilledRange = Object.keys(nonBilledFilters)
        //       .filter((range) => nonBilledFilters[range].isSelected)
        //       .map((range) => nonBilledFilters[range].filterValue)[0];

        //     if (selectedNonBilledRange) {
        //       const currentDate = new Date("2024-10-22T16:00:00");
        //       const lastOrderDate = new Date(outlet.Last_Order_Date);

        //       const differenceInDays = Math.ceil(
        //         (currentDate - lastOrderDate) / (1000 * 60 * 60 * 24)
        //       );

        //       if (differenceInDays > selectedNonBilledRange) {
        //         return false;
        //       }
        //     }
        //   }
        //   return true;
        // })
        .sort((a, b) => {
          if (selectedSortOption === "" || selectedSortOption === "distance") {
            const distanceA = parseFloat(a.Distance.replace(" km", ""));
            const distanceB = parseFloat(b.Distance.replace(" km", ""));

            return distanceA - distanceB;
          }
          if (selectedSortOption === "maxSales") {
            const salesA = parseFloat(a.averageSale);
            const salesB = parseFloat(b.averageSale);
            return salesB - salesA;
          }
          if (selectedSortOption === "probability") {
            const orderProbabilityA = parseFloat(a.orderProbability);
            const orderProbabilityB = parseFloat(b.orderProbability);
            return orderProbabilityB - orderProbabilityA;
          }
          if (selectedSortOption === "opportunity") {
            const orderOpportunityA = parseFloat(a.orderOpportunity);
            const orderOpportunityB = parseFloat(b.orderOpportunity);
            return orderOpportunityB - orderOpportunityA;
          }
          return 0;
        })
    : null;

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(paginatedOutlets?.length / outletsPerPage);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const [currentOutlets, setCurrentOutlets] = useState([]);

  useEffect(() => {
    const updatedOutlets = paginatedOutlets?.slice(
      (currentPage - 1) * outletsPerPage,
      currentPage * outletsPerPage
    );
    setCurrentOutlets((prevOutlets) => {
      return JSON.stringify(prevOutlets) !== JSON.stringify(updatedOutlets)
        ? updatedOutlets
        : prevOutlets;
    });
  }, [paginatedOutlets, currentPage, outletsPerPage]);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState("");
  const [errorRadius, setErrorRadius] = useState("");
  const [useCurrentLocation, setUseCurrentLocation] = useState(true);
  useEffect(() => {
    if (useCurrentLocation) {
      fetchCurrentLocationAndAddress();
    } else {
      handleUpdateLocation();
    }
  }, [useCurrentLocation]);

  const handleUpdateLocation = async () => {
    const newLocation = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    };

    setCurrentLocation(newLocation);
    await fetchCurrentAddress(newLocation);
  };

  useEffect(() => {
    fetchCurrentLocationAndAddress();
  }, []);
  const fetchCurrentLocationAndAddress = () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        const TOLERANCE = 0.0001;
        if (
          currentLocation &&
          Math.abs(currentLocation.lat - location.lat) < TOLERANCE &&
          Math.abs(currentLocation.lng - location.lng) < TOLERANCE
        ) {
          return;
        }
        setCurrentLocation(location);
        await fetchCurrentAddress(location);
      },
      (error) => {
        console.error("Error fetching location:", error);
      }
    );
  };

  const fetchCurrentAddress = async (location) => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const response = await fetch(`${process.env.REACT_APP_GEOCODE_API}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({
          lat: location.lat,
          lng: location.lng,
        }),
      });
      const data = await response.json();
      setCurrentAddress(data.response || "Address not found");
      setIsSliderOpen(true);
    } catch (err) {
      console.error("Error fetching address from backend:", err);
      setCurrentAddress("Unable to fetch address");
    }
  };
  // function daysSinceReference(dateStr) {
  //   const referenceDate = new Date("2000-01-01");
  //   const date = new Date(dateStr);
  //   return Math.floor((date - referenceDate) / (1000 * 60 * 60 * 24));
  // }

  function validateRadius(radius, setErrorRadius) {
    if (!radius || radius <= 0) {
      setErrorRadius("Radius must be greater than 0");
      return false;
    }
    setErrorRadius("");
    return true;
  }
  async function fetchOutletsFromAPI(currentLocation, radius, beat, filters) {
    const apiUrlOutlets = process.env.REACT_APP_OUTLETS_API;
    const access_token = sessionStorage.getItem("access_token");
    const response = await fetch(apiUrlOutlets, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({ currentLocation, radius, beat, filters }),
    });
    return response.json();
  }

  async function fetchFilteredOutlets() {
    if (!currentLocation) return;
    if (!validateRadius(radius, setErrorRadius)) return;

    setHasSearched(true);
    setRadius(radius);
    setIsLoading(true);

    try {
      const activeCategoryFilters = Object.keys(categorySizeFilters)
      .filter((key) => key !== "ALL" && categorySizeFilters[key]); 

    const activeNonBilledFilters = Object.keys(nonBilledFilters)
      .filter((key) => key !== "ALL" && nonBilledFilters[key].isSelected)
      .map((key) => nonBilledFilters[key].filterValue);

      const filters = {
        ...(selectedFilters.categorySize &&
          activeCategoryFilters.length > 0 && {
            categorySize: activeCategoryFilters,
          }),
        ...(selectedFilters.nonBilled &&
          activeNonBilledFilters.length > 0 && {
            nonBilled: activeNonBilledFilters,
          }),
      };

      let data = await fetchOutletsFromAPI(
        currentLocation,
        radius,
        selectedBeat,
        filters
      );

      // const currentDate = new Date();

      setFilteredOutlets(data || []);
    } catch (error) {
      console.error("Error fetching outlets:", error);
      setFilteredOutlets([]);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchDirections = (selectedOutlets) => {
    if (!currentLocation || !selectedOutlets || selectedOutlets?.length === 0) {
      console.error("Invalid inputs for fetching directions.");
      return;
    }
    const directionsService = new window.google.maps.DirectionsService();

    const waypoints = selectedOutlets.map((outlet) => ({
      location: {
        lat: parseFloat(outlet.lat),
        lng: parseFloat(outlet.lng),
      },
      stopover: true,
    }));

    const finalDestination = waypoints.pop().location;

    directionsService.route(
      {
        origin: currentLocation,
        destination: finalDestination,
        waypoints: waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(),
          trafficModel: "bestguess",
        },
      },
      (result, status) => {
        if (status === "OK" && result) {
          setDirections(result);
        } else {
          console.error("Error fetching directions:", status, result);
        }
      }
    );
  };
  const handleGetRoute = () => {
    if (wayPoints?.length === 0) {
      alert("Please select at least one outlet to fetch the route.");
      return;
    }
    const waypoints = wayPoints?.map((outlet) => ({
      lat: outlet.Latitude,
      lng: outlet.Longitude,
    }));

    fetchDirections(waypoints);
  };

  const handleSelectOutlet = (outlet) => {
    setWayPoints((prevSelected = []) => {
      if (prevSelected.includes(outlet)) {
        return prevSelected.filter((o) => o !== outlet);
      } else {
        return [...prevSelected, outlet];
      }
    });
  };

  const generateGoogleMapsLink = (directions) => {
    if (!directions) return null;
    const { origin, destination, waypoints } = directions.request;
    const originLatLng = `${origin.location.lat()},${origin.location.lng()}`;
    const destinationLatLng = `${destination.location.lat()},${destination.location.lng()}`;
    const waypointsLatLng = waypoints
      .map((waypoint) => {
        const nestedLocation = waypoint.location.location;
        return typeof nestedLocation.lat === "function"
          ? `${nestedLocation.lat()},${nestedLocation.lng()}`
          : `${nestedLocation.lat},${nestedLocation.lng}`;
      })
      .join("|");

    const googleMapsLink = `https://www.google.com/maps/dir/?api=1&origin=${originLatLng}&destination=${destinationLatLng}&travelmode=driving&waypoints=${waypointsLatLng}`;

    return googleMapsLink;
  };
  useEffect(() => {
    if (directions && directions.routes?.length > 0) {
      const route = directions.routes[0].legs;
      const totalDistance = route.reduce(
        (sum, leg) => sum + leg.distance.value,
        0
      );
      const drivingDuration = route.reduce(
        (sum, leg) =>
          sum +
          (leg.duration_in_traffic
            ? leg.duration_in_traffic.value
            : leg.duration.value),
        0
      );
      const numWaypoints = route.length - 1;
      const stopDuration = numWaypoints * 600;
      const totalDuration = drivingDuration + stopDuration;
      const Mapslink = generateGoogleMapsLink(directions);
      const formatDuration = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return hours > 0 ? `${hours} hr ${minutes} min` : `${minutes} min`;
      };
      setGoogleMapsLink(Mapslink);
      setDistance(`${(totalDistance / 1000).toFixed(2)} km`);
      setDuration(`${formatDuration(drivingDuration)} (Real-time Driving)`);
      setEta(`${formatDuration(totalDuration)} (Including Stops)`);
    }
  }, [directions]);

  const [openSection, setOpenSection] = useState(null);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [selectedLeg, setSelectedLeg] = useState(null);

  const handleOutletClick = (outlet, index) => {
    setSelectedOutlet(outlet);
    if (directions && directions.routes.length > 0) {
      const route = directions.routes[0];
      const leg = route.legs[index];
      setSelectedLeg(leg);
    }
    setOpenSection(null);
  };
  const toggleSection = (section) => {
    setOpenSection((prevSection) => (prevSection === section ? null : section));
  };

  const handleBack = () => {
    setDirections(null);

    setWayPoints([]);
    setSelectedLeg(null);
    setSelectedOutlet(null);
  };

  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
        height: isMediumDown ? "100vh" : "83vh",
        flexDirection: isMediumDown ? "column" : "row",
        marginTop: isMediumDown ? "" : "7%",
      }}
    >
      {isMediumDown && (
        <div
          style={{
            display: "flex",
            width: "95%",
            justifyContent: "space-between",
            backgroundColor: "#f0f0f0",
            padding: "2%",
            alignItems: "center",
          }}
        >
          <img
            fetchpriority="high"
            width={"100"}
            height={"44"}
            src="https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1.png"
            className="attachment-full size-full wp-image-2014"
            alt=""
            srcSet="https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1.png 500w, https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1-300x133.png 300w"
            sizes="(max-width: 500px) 100vw, 500px"
          />
          <img
            alt="NoviroLogo"
            src={require("../assets/Noviro.png")}
            width={"100"}
            height={"35"}
          />
        </div>
      )}
      {isMediumDown && (
        <SlidingComponent
          hasSearched={hasSearched}
          setIsExpanded={setIsExpanded}
          IsExpanded={IsExpanded}
          currentAddress={currentAddress}
          filteredOutlets={filteredOutlets}
          setFilteredOutlets={setFilteredOutlets}
          wayPoints={wayPoints}
          currentOutlets={currentOutlets}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          handleGetRoute={handleGetRoute}
          handleSelectOutlet={handleSelectOutlet}
          googleMapsLink={googleMapsLink}
          distance={distance}
          eta={eta}
          duration={duration}
          directions={directions}
          selectedLeg={selectedLeg}
          handleBack={handleBack}
          selectedOutlet={selectedOutlet}
          handleOutletClick={handleOutletClick}
          toggleSection={toggleSection}
          openSection={openSection}
          latitude={latitude}
          setLatitude={setLatitude}
          longitude={longitude}
          setLongitude={setLongitude}
          error={error}
          setError={setError}
          setUseCurrentLocation={setUseCurrentLocation}
          handleUpdateLocation={handleUpdateLocation}
          isLoading={isLoading}
          radius={radius}
          selectedSortOption={selectedSortOption}
        />
      )}

      <InfoContainer
        errorRadius={errorRadius}
        currentLocation={currentLocation}
        currentAddress={currentAddress}
        filteredOutlets={filteredOutlets}
        directions={directions}
        fetchFilteredOutlets={fetchFilteredOutlets}
        setRadius={setRadius}
        radius={radius}
        wayPoints={wayPoints}
        selectedSortOption={selectedSortOption}
        setSelectedSortOption={setSelectedSortOption}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        nonBilledFilters={nonBilledFilters}
        setNonBilledFilters={setNonBilledFilters}
        categorySizeFilters={categorySizeFilters}
        setCategorySizeFilters={setCategorySizeFilters}
        currentOutlets={currentOutlets}
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        handleGetRoute={handleGetRoute}
        handleSelectOutlet={handleSelectOutlet}
        googleMapsLink={googleMapsLink}
        distance={distance}
        eta={eta}
        duration={duration}
        selectedLeg={selectedLeg}
        handleBack={handleBack}
        selectedOutlet={selectedOutlet}
        handleOutletClick={handleOutletClick}
        toggleSection={toggleSection}
        openSection={openSection}
        hasSearched={hasSearched}
        isLoading={isLoading}
        selectedBeat={selectedBeat}
        setSelectedBeat={setSelectedBeat}
      />
      <div style={{ flex: 2 }}>
        {currentLocation && (
          <MapContainer
            currentLocation={currentLocation}
            filteredOutlets={currentOutlets}
            directions={directions}
          />
        )}
      </div>
    </div>
  );
};

export default OutletMaps;
